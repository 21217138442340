import React from "react"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import GenericPage from "../components/generic-page";
import "../style/login.scss";
import LoginForm from "../components/login";
// markup
export default function Login() {
  return (<GenericPage title="Login">
    <LoginForm />
  </GenericPage>);
}

