import React from "react";

import {withFormsy} from "formsy-react";
import Form from "react-bootstrap/Form";
import {useEffect} from "react";


export default withFormsy(function TextField(props) {
  const {
    onChange, onFocus, clearOnFocus, defaultValue, disabled,
    value,
    showError,
    errorMessage,
    isValid,
    setValue,
    isValidValue,
    attachToForm,
    detachFromForm,
    isFormDisabled,
    runValidation,
    innerRef,
    validationError,
    validationErrors,
    errorMessages,
    hasValue,
    isFormSubmitted,
    isPristine,
    isRequired,
    resetValue,
    setValidations,
    showRequired,
    validate,
    ...rest
  } = props;

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, []);
  function handleOnChange(e) {
    setValue(e.currentTarget.value);
  }
  function handleOnFocus(e) {
    if (onFocus) {
      onFocus(e);
    }
    if (clearOnFocus) {
      setValue("");
    }
    return undefined;
  }
  return (<>
    <Form.Control
      onChange={(e) => handleOnChange(e)}
      onFocus={(e) => handleOnFocus(e)}
      isInvalid={showError}
      value={value || ""}
      disabled={isFormDisabled || disabled}
      {...rest}>
      {props.children}
    </Form.Control>
    {(showError && errorMessage) && (<Form.Control.Feedback type="invalid">
      {errorMessage}
    </Form.Control.Feedback>)}
  </>);
});
