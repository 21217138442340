import React from "react"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Formsy from "formsy-react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { loginMutation, loginMutationOptions, loginMutationResult } from "../logic/user";
import FormControl from "./formsy/form-control";

export default function LoginForm() {

  const [login] = useMutation(loginMutation);
  async function handleValidSubmit(model, resetForm, invalidateForm) {
    console.log("model", model);
    let loginResult;
    try {
      const loginResponse = await login({
        variables: model,
        ...loginMutationOptions,
      });
      loginResult = loginMutationResult(loginResponse);
      console.log("loginResult", loginResult);
    } catch (err) {
      console.log("error occurred", err);
    }
    // logger.debug("login result", loginResult);
    if (!loginResult) {
      invalidateForm({
        username: "",
        password: "Invalid Username or Password",
      });
    } else {
      console.log("Portal?");
      window.location = "/portal";
    }
  }

  return (<Container fluid className="fh fh-element">
    <Row className="fh-element align-items-center">
      <Col xs={12} md={6} lg={6} className="mx-auto">
        <div className="login-form">
          <Formsy onValidSubmit={handleValidSubmit}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{"Username"}</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fal fa-user" />
                  </InputGroup.Text>
                  <FormControl autoFocus name="username" required />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="col-12">
                <Form.Label>{"Password"}</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fal fa-lock" />
                  </InputGroup.Text>
                  <FormControl name="password" type="password" required />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="align-items-center justify-content-end">
              <Col xs="auto">
                <Button variant="primary" type="submit">{"Login"}</Button>
              </Col>
            </Row>
          </Formsy>
        </div>
      </Col>
    </Row>
  </Container>);
}
