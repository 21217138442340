import React from "react"
import Layout from "../layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
// import { imageQuery } from "../pages";

import LogoImage from "../images/logo-gold.png";
// import { useStaticQuery } from "gatsby";

import HomeImage from "../images/bg.jpg";

// markup
export default function GenericPage(props) {
  // const data = useStaticQuery(imageQuery);
  return (<Layout>
    <div className="snp-header" style={{
      height: 100, width: "100%",
      backgroundImage: `url(${HomeImage})`,
      backgroundSize: "cover",
      backgroundPosition: "right bottom",
      position: "fixed", top: 0, left: 0, right: 0
    }}>
      <div className="snp-header-bg" style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0}}>
        <Container fluid>
          <Row style={{height: 100}} className="logo-row align-items-center">
            <Col className="ml-auto" xs={"auto"}>
              <img src={LogoImage} style={{height: 90}} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    {/* <div className="snp-white-logo">
      <img src={"/logos/Sharon-Newman_logo_RGB_black.png"} style={{
        width: 300,
        opacity: 0.8
      }} />

    </div>
    <div className="snp-topbar" /> */}
    <Container className="fh fh-element">
      <Row className="fh fh-element align-items-center">
        <Col className={`mx-auto ${props.fh ? "fh fh-element" : ""} ${props.className ? props.className : ""}`} sm={8} style={props.style}>
          <div className="content">
            <div className="header">
              {props.title && (<>
                <h1>{props.title}</h1>
                <div className="spacer">
                </div>
              </>)}
            </div>
            {props.children}
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>);
}